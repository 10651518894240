/*
 * Active anchors
 */
#anchors-list li a.active {
    margin-left: 0.3em;
    font-size: large;
    text-decoration: none;
    transform: scale(1.05);
    font-weight: bold;
    transition: all ease 0.3s;
    border-radius: 15% 15%;
}

/*
* sidebar section
*/
.sidebar-section {
    border: var(--bs-border-width) var(--bs-border-style) rgba(0,0,0,0.0)!important;
}

.sidebar-section:hover {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
    border-color: var(--bs-info-border-subtle)!important;
}
