/* custom style */

/* html {
    scroll-padding-top: 1.5rem; /* height of your navbar */
/*} */

/* body {
    background-color: #e5e5e5;
} */

h1, h2, h3, h4, h5, h6 {
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
}

.display-4 {
    font-size: 2.5rem;
}
@media (min-width: 768px) {
    .display-4 {
        font-size: 3rem;
    }
}

.flex-auto {
    flex: 0 0 auto;
}

.h-250 { height: 250px; }
@media (min-width: 768px) {
    .h-md-250 { height: 250px; }
}


/*
* parts
*/
.part-title {
    font-size: 2.5rem;
}
.part-meta {
    color: #727272;
}

/*
* cv section
*/
.cv-section {
    border: var(--bs-border-width) var(--bs-border-style) rgba(0,0,0,0.0)!important;
}

.cv-section:hover {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
    border-color: var(--bs-primary-border-subtle)!important;
}
